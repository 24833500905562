<template>
  <div class="container-register">
    <h3 class="text-center">
      {{ method == "edit" ? "تعديل بيانات المعدة" : "أضافة نوع جديد" | translate }}
    </h3>
    <hr />
    <br /><br /><br />
    <div v-if="loading" class="center">
      <loading />
    </div>
    <div class="">
      <div class="row g-3" dir="rtl">
        <div class="col-md-6">
          <div class="form-group">
            <label class="title-2">{{ "الأسم" | translate }}</label>
            <input type="text" class="form-control" v-model="name" />
          </div>
        </div>
        <!------------------------>
        <div class="col-md-6">
          <div class="form-group">
            <label class="title-2"> {{ "الآفَة" | translate }} </label>
            <span style="opacity: 0.8; font-size: 0.9rem">
              ({{ "اذا كان لا يوجد حشرات ضع صفر" | translate }})</span
            >
            <input type="text" class="form-control" v-model="element" />
          </div>
        </div>
        <hr />
        <br />
        <!------------------------>
        <div class="col-md-12">
          <div class="form-group">
            <h4 class="title-2 text-center">{{ "الحالات" | translate }}</h4>
            <div class="row g-2">
              <div v-for="state in states" :key="state.code" class="col-md-6">
                <div class="bordered">
                  <div class="row g-2">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>{{ "الكود" | translate }}</label>
                        <input type="text" class="form-control" v-model="state.code" />
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group">
                        <label>{{ "الوصف" | translate }}</label>
                        <input type="text" class="form-control" v-model="state.desc" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-------------->
            <div class="row justify-content-center">
              <div class="col-md-4">
                <div class="plus-minus d-flex justify-content-between">
                  <button
                    @click="increaseState()"
                    class="btn btn-outline-secondary btn-rounded"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                  <button
                    @click="decreaseState()"
                    class="btn btn-outline-secondary btn-rounded"
                  >
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--------------------------->
        <hr />
        <br />
        <!--------------------------->
        <div class="col-md-12">
          <div class="form-group">
            <h4 class="title-2 text-center">{{ "الأجراءات" | translate }}</h4>
            <div class="row g-2">
              <div v-for="procedure in procedures" :key="procedure.code" class="col-md-6">
                <div class="bordered">
                  <div class="row g-2">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>{{ "الكود" | translate }}</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="procedure.code"
                        />
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group">
                        <label>الوصف</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="procedure.desc"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!---------->
            <div class="row justify-content-center">
              <div class="col-md-4">
                <div class="plus-minus d-flex justify-content-between">
                  <button
                    @click="increaseProcedure()"
                    class="btn btn-outline-secondary btn-rounded"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                  <button
                    @click="decreaseProcedure()"
                    class="btn btn-outline-secondary btn-rounded"
                  >
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---------------------------->
      <!---------------------------->
      <hr />
      <div v-if="loading" class="center">
        <loading />
      </div>
      <div class="row justify-content-center">
        <div class="col-md-4 center">
          <button @click="upload" class="btn btn-success">upload</button>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  created() {
    if (this.$route.params.id) {
      this.method = "edit";
    } else this.method = "add";

    if (this.method === "edit") {
      axios.get("/getCategoryById/" + this.$route.params.id).then((res) => {
        if (res.data.err) return this.$store.dispatch("writeMessage", res.data.err);
        const category = { ...res.data.category };
        this.id = category._id;
        this.name = category.name;
        this.element = category.element;
        this.states = category.states;
        this.procedures = category.procedures;
      });
    }
  },
  components: {},
  data() {
    return {
      method: "add",
      loading: false,
      id: null,
      name: null,
      element: null,
      states: [{ code: "", desc: "" }],
      procedures: [{ code: "", desc: "" }],
    };
  },
  computed: {},
  methods: {
    upload() {
      if (!this.complete) return;
      this.loading = true;
      const category = {
        name: this.name,
        element: this.element,
        states: this.states,
        procedures: this.procedures,
      };
      const method = this.method == "edit" ? "/editCategory/" + this.id : "/addCategory";
      console.log(method);
      axios.post(method, category).then((res) => {
        this.loading = false;
        if (res.data.err) {
          return this.$store.dispatch("writeMessage", res.data.err);
        }
        if (res.data.category) this.id = res.data.category._id;
        this.$store.dispatch("writeMessage", "تم اضافة المعدة بنجاح");
        this.$store.dispatch("getCategories");
      });
    },
    increaseState() {
      this.states.push({ code: "", desc: "" });
    },
    decreaseState() {
      this.states.pop();
    },
    increaseProcedure() {
      this.procedures.push({ code: "", desc: "" });
    },
    decreaseProcedure() {
      this.procedures.pop();
    },
    complete() {
      return (
        this.name.length > 1 &&
        this.element.length > 1 &&
        this.states.length > 0 &&
        this.procedures.length > 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  direction: rtl;
  text-align: right;
}
.container-register {
  padding: 20px;
  // background: rosybrown;
  @media (max-width: 500px) {
    padding: 60px 2px;
  }
}
.form-group {
  .error-msg {
    color: #dc3545;
    margin-top: 5px;
    &::before {
      content: "\f06a";
      font: normal normal normal 14px/1;
      font-size: 14px;
      font-size: inherit;
      text-rendering: auto;
      -moz-osx-font-smoothing: grayscale;
      margin-left: 5px;
    }
  }
}

button {
  text-align: center;
  margin: 15px;
}
</style>
